import React, { Component } from "react";
import { SortableContainer, SortableElement, arrayMove } from "react-sortable-hoc";
import { ListIcon } from "../icons/icons";
import { sortMenus } from "../../functions/postFunctions";
import { CustomButton, LinkButton } from "../Buttons/Button";

class SortableList extends Component {
  state = {
    items: [],
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState(
      {
        items: arrayMove(this.state.items, oldIndex, newIndex),
      },
      () => this.handleSortMenus()
    );
  };

  componentDidMount() {
    this.setState({ items: this.props.items });
  }

  handleSortMenus = () => {
    sortMenus(this.state.items, this.props.token).then((res) => null);
  };

  render() {
    const SortableItem = SortableElement(({ item, index }) => (
      <li
        className={`level-${item.level}`}
        style={{ paddingLeft: `${item.level * 20}px`, listStyle: "none" }}
        key={index}>
        <span
          className='f-s-16 d-flex align-items-center justify-content-between pl-10'
          style={{ height: "50px" }}>
          <span className='left d-flex align-items-center'>
            <ListIcon className='fill mr-10' />
            {item.name}
          </span>
          <span className='right d-flex align-items-center'>
            <CustomButton
              className='mr-20'
              onClick={() => this.props.showModal("showEditMenuItem", item.id)}>
              Izmjeni
            </CustomButton>
            <CustomButton className='red' onClick={() => this.props.deleteCategory(item.id)}>
              Izbriši
            </CustomButton>
          </span>
        </span>
        {item.children.length > 0 && this.props.listElement(item.children)}
      </li>
    ));

    const SortableList = SortableContainer(({ items }) => {
      return (
        <ul>
          {items.map((item, index) => (
            <React.Fragment key={index}>
              <SortableItem index={index} item={item} />
            </React.Fragment>
          ))}
        </ul>
      );
    });

    return (
      <SortableList
        items={this.state.items}
        onSortEnd={this.onSortEnd}
        axis='y'
        helperClass='SortableHelper'
        key={this.props.key}
      />
    );
  }
}

export default SortableList;
