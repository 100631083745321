import React, { Component } from "react";
import { CustomInput, CustomTextArea } from "../Form/Inputs/Inputs";
import { CustomButton } from "../Buttons/Button";
import {
  addMenuItemValidiation,
  addProductCategoryValidation,
} from "../../functions/validateFunctions";
import { addProductCategory } from "../../functions/postFunctions";
import SearchableDropdown from "../Form/Dropdown/SearchableDropdown";
import { createSlug } from "../../functions/functions";
import SearchableDropdownMultiple from "../Form/Dropdown/SearchableDropdownMultiple";

class AddProductCategory extends Component {
  state = {
    name: "",
    logo: null,
    showErrorPopup: false,
    errors: [],
    responseErrors: [],
    menus: [],
    linkTypes: [
      { value: 0, label: "Interni link" },
      { value: 1, label: "Eksterni link" },
      // { value: 2, label: "Fajl" },
      { value: 3, label: "Placeholder" },
    ],
    linkType: null,
    link: "",
    external: false,
    pages: [],
    parent_id: null,
    language_id: 1,
    order: 0,
    description: "",
  };

  defaultState = {
    name: "",
    logo: null,
    showErrorPopup: false,
    errors: [],
    responseErrors: [],
    menus: [],
    linkTypes: [
      { value: 0, label: "Interni link" },
      { value: 1, label: "Eksterni link" },
      // { value: 2, label: "Fajl" },
      { value: 3, label: "Placeholder" },
    ],
    linkType: null,
    link: "",
    external: false,
    pages: [],
    parent_id: null,
    language_id: 1,
    order: 0,
  };

  componentDidMount() {}

  handleInput = (name, value) => {
    this.setState({ [name]: value });
  };

  handleFileInput = (event) => {
    this.setState({ logo: [...event] });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const { parent_id, name, language_id, description } = this.state;

    const orderList = this.state.menus.filter((e) => e.parent_id === parent_id);
    const parentLevel = this.state.menus.find((e) => e.id === parent_id);
    let order = 0;
    let level = 0;
    if (parentLevel) {
      level = parentLevel.level + 1;
    }
    if (orderList.length > 0) {
      order =
        Math.max.apply(
          Math,
          orderList.map((e) => e.order)
        ) + 1;
    }

    const data = {
      name,
      order,
      level,
    };
    const errors = addProductCategoryValidation(this.state);
    if (errors.length === 0) {
      this.props.addToList([]);
      addProductCategory(name, order, level, this.props.token).then((res) => {
        if (res.success) {
          this.setState({ name: "So", linkType: null, parent_id: null });
          this.props.addToList(res.categories);
        } else {
          let responseErrors = [];
          Object.keys(res.errors).forEach((key, index) => {
            responseErrors.push(res.errors[key]);
          });
          this.props.addToList(this.props.menus);
          this.setState({ responseErrors });
        }
      });
    } else {
      this.setState({ errors });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.errors !== this.state.errors && this.state.errors.length > 0) {
      this.setState({ showErrorPopup: true });
    }
    if (
      prevState.responseErrors !== this.state.responseErrors &&
      this.state.responseErrors.length > 0
    ) {
      this.setState({ showErrorPopup: true });
    }
    if (prevProps.menus !== this.props.menus) {
      this.setState({ menus: this.findAllMenus(this.props.menus) });
    }
    if (prevState.linkType !== this.state.linkType) {
      const { linkType } = this.state;
      if (linkType === 1) {
        this.setState({ external: true });
      } else {
        this.setState({ external: false });
      }
    }
  }

  removePopup = () => {
    this.setState({ showErrorPopup: false, errors: [], responseErrors: [] });
  };

  findAllMenus = (array) => {
    let menus = [];
    const handleRecursion = (arr) => {
      arr.forEach((e) => {
        menus.push(e);
        if (e.children.length > 0) {
          handleRecursion(e.children);
        }
      });
    };
    handleRecursion(array);
    return menus;
  };

  renderLink = (linkType) => {
    let item;
    switch (linkType) {
      case 0:
        item = (
          <>
            <label
              htmlFor='linkType'
              className='f-s-14 text-color-font-l'
              style={{
                color: "#7ea5cb",
                marginBottom: "5px",
                display: "block",
              }}>
              Izaberite stranicu
            </label>
            <SearchableDropdown
              data={this.state.pages}
              placeholder='Izaberite stranicu'
              name='link'
              handleChange={this.handleInput}
            />
          </>
        );
        break;
      case 1:
        item = (
          <>
            <label
              htmlFor='linkType'
              className='f-s-14 text-color-font-l'
              style={{
                color: "#7ea5cb",
                marginBottom: "5px",
                display: "block",
              }}>
              Link
            </label>
            <CustomInput
              value={this.state.link}
              handleChange={this.handleInput}
              name='link'
              index={0}
              counter={false}
            />
          </>
        );
        break;
      case 2:
        item = 2;
        break;
      case 3:
        item = "";
        break;
      default:
        item = "";
        break;
    }
    return item;
  };

  render() {
    const { name, menus, description } = this.state;
    const { visible, hideModal } = this.props;

    let options = [];
    let option = null;
    menus.forEach((e) => {
      if (e.level === 0) {
        if (option) {
          options.push(option);
        }
        option = null;
        option = {
          label: e.name,
          options: [{ value: e.id, label: e.name }],
        };
      } else {
        option.options.push({ value: e.id, label: e.name });
      }
    });

    const menuList = [
      {
        value: null,
        label: "Nekategorisano",
      },
      ...options,
    ];
    return (
      <div
        className={`addTeamModal d-flex justify-content-center align-items-center ${
          visible ? "visible" : ""
        }`}>
        <div className={` errorPopup ${this.state.showErrorPopup ? "popupShown" : ""}`}>
          <div className='content py-20 px-10 d-flex justify-content-between flex-column'>
            <h3 className='f-s-18 f-w-4 uppercase text-center'>
              Problemi prilikom kreiranja stavke
            </h3>
            {this.state.errors.length > 0 ? (
              <ul className='mt-30'>
                {this.state.errors.map((e, index) => {
                  return (
                    <li key={index} className='f-s-16 text-center'>
                      {e}
                    </li>
                  );
                })}
              </ul>
            ) : (
              <ul className='mt-30'>
                {this.state.responseErrors.map((e) => {
                  return e.map((el, index) => (
                    <li key={index} className='f-s-16 text-center'>
                      {e}
                    </li>
                  ));
                })}
              </ul>
            )}
            <div className='button text-center mt-30'>
              <CustomButton onClick={this.removePopup}>Zatvori</CustomButton>
            </div>
          </div>
        </div>
        <div className='addTeamContent py-30 px-30'>
          <h4 className='text-color-primary f-s-20 lh text-center mb-20'>Dodaj kategoriju</h4>
          <div className='row mt-30'>
            <div className='col-md-12'>
              <label
                htmlFor='name'
                className='f-s-14 text-color-font-l'
                style={{
                  color: "#069447",
                  marginBottom: "5px",
                  display: "block",
                }}>
                Naziv
              </label>
              <CustomInput
                // label="Naziv"
                value={name}
                handleChange={this.handleInput}
                name='name'
                index={0}
                counter={false}
              />
            </div>
            {/* <div className='col-md-6'>
              <label
                htmlFor='parentMenu'
                className='f-s-14 text-color-font-l'
                style={{
                  color: "#069447",
                  marginBottom: "5px",
                  display: "block",
                }}>
                Roditeljska kategorija
              </label>
              <SearchableDropdownMultiple
                data={menuList}
                placeholder='Roditeljska kategorija'
                name='parent_id'
                handleChange={this.handleInput}
              />
            </div> */}
          </div>
          <div className='row mt-30'>
            <div className='col-12'>
              <label
                htmlFor='parentMenu'
                className='f-s-14 text-color-font-l'
                style={{
                  color: "#069447",
                  marginBottom: "5px",
                  display: "block",
                }}>
                Opis kategorije
              </label>
              <CustomTextArea
                // label="Naziv"
                value={description}
                handleChange={this.handleInput}
                name='description'
                index={0}
                counter={false}
              />
            </div>
          </div>
          <div className='row mt-40'>
            <div className='col-lg-12 d-flex justify-content-center'>
              <CustomButton className='mr-30' onClick={(e) => this.handleSubmit(e)}>
                Dodaj stavku
              </CustomButton>
              <CustomButton className='red' onClick={hideModal}>
                Odustani
              </CustomButton>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AddProductCategory;
