import React, { Component } from "react";
import {
  deleteOrder,
  getAllOrders,
  getAllProducts,
  getOrder,
} from "../../../functions/postFunctions";
import { LinkButton, CustomButton } from "../../../components/Buttons/Button";
import Table from "../../../components/Table/Table";
import { dateLocalisation } from "../../../functions/functions";

class OrderDetails extends Component {
  state = {
    theads: ["Naziv proizvoda", "Šifra proizvoda", "Količina", "Cijena", "Total"],
    order: {
      items: [],
    },
    removeLoadMore: true,
    page: 1,
    categoryList: [],
    currentCategory: null,
  };

  componentDidMount() {
    this.props.setBreadcrumbs(`Porudžbina #${this.props.match.params.id}`);

    getOrder(this.props.match.params.id).then((res) => {
      if (res.success) {
        this.setState({ order: res.order });
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.currentCategory !== this.state.currentCategory && this.state.currentCategory) {
      this.loadNews();
    }
  }

  handleDeletePage = (id) => {
    const { orders } = this.state;
    const newOrders = orders.filter((e) => e.id !== id);
    deleteOrder(id, this.props.token).then((res) => {
      if (res.success) {
        this.setState({ orders: newOrders });
      }
    });
  };

  loadNews = () => {
    const { currentCategory } = this.state;
    getAllProducts(20, 0, currentCategory.value).then((res) => {
      if (res.success) {
        this.setState({
          products: res.products,
          page: 1,
          removeLoadMore: res.products.length < 20,
        });
      }
    });
  };

  loadMoreNews = () => {
    const { page, currentCategory } = this.state;
    getAllProducts(20, page * 20, currentCategory.value).then((res) => {
      if (res.success) {
        this.setState(({ products, page }) => ({
          products: [...products, ...res.products],
          page: page + 1,
          removeLoadMore: res.products.length < 20,
        }));
      }
    });
  };

  handleInput = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  render() {
    const { theads, order, categoryList, currentCategory } = this.state;
    let totalPrice = 0;
    return (
      <div>
        <div className='pageTop mb-40'>
          <div className='col-12'>
            <div className='wrapper'>
              <div className='info'>
                <p className='f-s-16'>
                  <span className='f-w-6' style={{ minWidth: "100px", display: "inline-block" }}>
                    Ime:
                  </span>{" "}
                  <span className='ml-10'>{order.name}</span>
                </p>
                <p className='f-s-16'>
                  <span className='f-w-6' style={{ minWidth: "100px", display: "inline-block" }}>
                    Email:
                  </span>{" "}
                  <span className='ml-10'>{order.email}</span>
                </p>
                <p className='f-s-16'>
                  <span className='f-w-6' style={{ minWidth: "100px", display: "inline-block" }}>
                    Telefon:
                  </span>{" "}
                  <span className='ml-10'>{order.phone}</span>
                </p>
                <p className='f-s-16'>
                  <span className='f-w-6' style={{ minWidth: "100px", display: "inline-block" }}>
                    Adresa:
                  </span>{" "}
                  <span className='ml-10'>{order.address}</span>
                </p>
                <p className='f-s-16'>
                  <span className='f-w-6' style={{ minWidth: "100px", display: "inline-block" }}>
                    Grad:
                  </span>{" "}
                  <span className='ml-10'>{order.city}</span>
                </p>
                <p className='f-s-16'>
                  <span className='f-w-6' style={{ minWidth: "100px", display: "inline-block" }}>
                    Tip:
                  </span>{" "}
                  <span className='ml-10'>{order.type}</span>
                </p>
                <p className='f-s-16'>
                  <span className='f-w-6' style={{ minWidth: "100px", display: "inline-block" }}>
                    Napomena:
                  </span>{" "}
                  <span className='ml-10'>{order.note}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <Table theads={theads}>
          {order.items.map((e, index) => {
            totalPrice += e.total;
            return (
              <tr key={index}>
                <td className='td px-10'>
                  <div className='wrapper d-flex flex-column'>
                    <span className='f-s-16'>{e.name}</span>
                  </div>
                </td>
                <td className='td px-10'>
                  <div className='wrapper d-flex flex-column'>
                    <span className='f-s-16'>
                      {e.product_code}-{e.product_color}
                    </span>
                  </div>
                </td>
                <td className='td px-10'>
                  <span className='f-s-16'>{e.amount}</span>
                </td>
                <td className='td px-10'>
                  <span className='f-s-16'>{e.price} &euro;</span>
                </td>
                <td className='td px-10' align='right'>
                  <span className='f-s-16'>{e.total} &euro;</span>
                </td>
              </tr>
            );
          })}
          <tr>
            <td colSpan={4} />
            <td style={{ backgroundColor: "#ceebff" }} align='right'>
              <span className='f-s-16'>{totalPrice.toFixed(2)} &euro;</span>
            </td>
          </tr>
        </Table>
        {!this.state.removeLoadMore ? (
          <div className='wrapper mt-40 d-flex justify-content-center'>
            <CustomButton onClick={this.loadMoreNews}>Učitaj još</CustomButton>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default OrderDetails;
