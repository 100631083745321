export const addPageValidiation = (state, edit) => {
  const errors = [];
  let titles = [];
  state.pages.map((e, index) => {
    // const jezik = index === 0 ? "Crnogorski" : "Engleski";
    if (index === 0) {
      if (e.title === "") {
        errors.push(`Naslov je obavezno polje!`);
      }
    } else if (index === 1) {
      if (e.title === "") {
        errors.push(`Naslov EN je obavezno polje!`);
      }
    }
    titles.push(e.title);
  });

  if (state.category_id === 0 && !edit) {
    errors.push(`Kategorija je obavezno polje!`);
  }

  const findDuplicates = (arr) => arr.filter((item, index) => arr.indexOf(item) !== index);
  const duplicates = findDuplicates(titles);

  let checkDups = 0;
  if(titles.length > 1){
    for(let k=0; k<titles.length-1; k++){
      if(titles[k] == titles[k+1]){
        checkDups = 1;
      }
    }
  }

  
  if (checkDups === 1) {
    errors.push(`Naslovi moraju biti različiti!`);
  }
  return errors;
};

export const addTeamValidation = (team, edit) => {
  const errors = [];

  if (team.name === "") {
    errors.push(`Naziv je obavezno polje!`);
  }
  if (team.location === "") {
    errors.push(`Lokacija je obavezno polje!`);
  }
  return errors;
};

export const addCompetitionValidation = (image) => {
  return [];
};

export const addProductCategoryValidation = (state, edit) => {
  const errors = [];
  const { name } = state;
  if (name === "") {
    errors.push(`Naziv je obavezno polje!`);
  }

  return errors;
};

export const addMenuItemValidiation = (state, edit) => {
  const errors = [];
  const { name, parent_id, linkType } = state;
  if (name === "") {
    errors.push(`Naziv je obavezno polje!`);
  }
  if (parent_id === null && !edit) {
    errors.push(`Roditeljski meni je obavezno polje!`);
  }
  if (linkType === null && !edit) {
    errors.push(`Tip linka je obavezno polje!`);
  }

  return errors;
};
export const addMenusValidation = (name, position) => {
  const errors = [];
  if (name === "") {
    errors.push(`Naziv je obavezno polje!`);
  }
  if (isNaN(position)) {
    errors.push(`Pozicija je obavezno polje!`);
  }
  return errors;
};

export const addGameValidation = (game) => {
  const errors = [];

  if (game.host === 0) {
    errors.push("Domaćin je obavezno polje!");
  }
  if (game.guest === 0) {
    errors.push("Gost je obavezno polje!");
  }
  if (!game.date) {
    errors.push("Datum je obavezno polje!");
  }
  if (game.competition === 0) {
    errors.push("Takmičenje je obavezno polje!");
  }
  if (game.location === "") {
    errors.push("Lokacija je obavezno polje!");
  }
  return errors;
};
export const addDiscountValidation = (state) => {
  const errors = [];

  if (state.name === "") {
    errors.push("Naziv je obavezno polje!");
  }
  if (state.discount === "") {
    errors.push("Popust je obavezno polje!");
  }
  if (state.date_from === null || state.date_from === undefined) {
    errors.push("Datum od je obavezno polje!");
  }
  if (state.date_to === null || state.date_to === undefined) {
    errors.push("Datum do je obavezno polje!");
  }
  return errors;
};
export const addPlayerValidation = (player, edit, firstTeam) => {
  const errors = [];

  if (player.name === "") {
    errors.push("Ime je obavezno polje!");
  }
  if (firstTeam) {
    if (player.number === null) {
      errors.push("Broj je obavezno polje!");
    }
    if (player.position === "") {
      errors.push("Pozicija je obavezno polje!");
    }
    if (player.birth_date === "") {
      errors.push("Datum rođenja je obavezno polje!");
    }
    if (player.birth_place === "") {
      errors.push("Mjesto rođenja je obavezno polje!");
    }
    if (!player.height) {
      errors.push("Visina je obavezno polje!");
    }
    if (!player.weight) {
      errors.push("Težina je obavezno polje!");
    }
    if (player.nationality === "") {
      errors.push("Nacionalnost je obavezno polje!");
    }
    if (player.clubs === "") {
      errors.push("Klubovi je obavezno polje!");
    }
    if (!player.image && !edit) {
      errors.push("Fotografija je obavezno polje!");
    }
  }

  return errors;
};

export const addStaffValidation = (player, edit) => {
  const errors = [];

  if (player.name === "") {
    errors.push("Ime je obavezno polje!");
  }
  if (player.position === "") {
    errors.push("Pozicija je obavezno polje!");
  }
  if (player.position_en === "") {
    errors.push("Pozicija EN je obavezno polje!");
  }
  if (player.birth_date === "") {
    errors.push("Datum rođenja je obavezno polje!");
  }
  if (player.birth_place === "") {
    errors.push("Mjesto rođenja je obavezno polje!");
  }

  if (player.nationality === "") {
    errors.push("Nacionalnost je obavezno polje!");
  }

  if (!player.image && !edit) {
    errors.push("Fotografija je obavezno polje!");
  }
  return errors;
};

export const addVideoValidation = (state) => {
  const errors = [];

  if (state.link === "") {
    errors.push("Link je obavezno polje!");
  }
  if (state.title === "") {
    errors.push("Naslov je obavezno polje!");
  }
  if (state.date === null || state.date === undefined) {
    errors.push("Datum je obavezno polje!");
  }
  return errors;
};

export const addProductValidation = (state, edit) => {
  const errors = [];
  if (state.name === "") {
    errors.push(`Naziv je obavezno polje!`);
  }
  if (state.category_id === 0 && !edit) {
    errors.push(`Kategorija je obavezno polje!`);
  }
  return errors;
};

// language_id: 1,
//     title: "",
//     description: "",
//     content: "",
//     slug: ""
