import React, { Component } from "react";
import { NavLink, Link } from "react-router-dom";

import Logo from "./Logo/Logo";

import { Home } from "./Icons/Icons";

import { moveIndicator } from "../../functions/functions";
import {
  PlayerIcon,
  PagesIcon,
  SettingsIcon,
  FootbalMatchIcon,
  TeamsIcon,
  TrophyIcon,
  TablesIcon,
  StaffIcon,
  VideosIcon,
  MenuIcon,
  SponsorsIcon,
  OrganisationsIcon,
  ProductIcon,
  OrdersIcon,
} from "../icons/icons";

class Navbar extends Component {
  state = {
    playersDropdown: false,
    pagesDropdown: false,
    productsDropdown: false,
  };
  linkHover = () => {
    const elements = document.getElementById("nav").querySelectorAll("a");

    for (let i = 0; i < elements.length; i++) {
      elements[i].onmouseover = function (e) {
        elements[i].setAttribute("data-hover", "hoverActive");
      };
      elements[i].onmouseout = function () {
        elements[i].setAttribute("data-hover", "hoverLeft");
      };
    }
  };
  componentDidMount() {
    this.linkHover();
  }

  toggleDropdown = (e, dropdown) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState((prevState) => ({
      [dropdown]: !prevState[dropdown],
    }));
  };

  closeDropdown = () => {
    this.setState({
      playersDropdown: false,
      pagesDropdown: false,
    });
  };

  render() {
    return (
      <>
        <nav id='nav'>
          <div className='logoContainer p-r d-flex justify-content-center align-items-center'>
            <Link to='/'>
              <Logo className='center-r-v pl-30' />
            </Link>
          </div>
          <div className='navContainer'>
            <ul className='f-s-12' onClick={this.closeDropdown}>
              <li>
                <NavLink exact={true} to='/meniji' className='p-r'>
                  <span className='icon d-inline-block mr-10'>
                    <MenuIcon className='fill' />
                  </span>
                  <span className='title d-inline-block'>Meniji</span>
                </NavLink>
              </li>
              <li>
                {/*<NavLink exact={true} to="/" className=" p-r">*/}
                {/*  <span className="icon d-inline-block mr-10">*/}
                {/*    <Home className="fill" />*/}
                {/*  </span>*/}
                {/*  <span className="title d-inline-block">Početna</span>*/}
                {/*</NavLink>*/}
              </li>
              <li className='subMenu'>
                <a
                  className='subMenuToggle p-r'
                  onClick={(e) => this.toggleDropdown(e, "pagesDropdown")}>
                  <span className='icon d-inline-block mr-10'>
                    <PagesIcon className='fill' />
                  </span>
                  <span className='title d-inline-block'>Stranice</span>
                </a>
                <div
                  style={{ height: this.state.pagesDropdown ? "100px" : 0 }}
                  className='subMenuDropdown'
                  id='playersDropdown'>
                  <NavLink exact={true} to='/' className='p-r' onClick={(e) => e.stopPropagation()}>
                    <span className='pl-15 title d-inline-block'>Stranice</span>
                  </NavLink>
                  <NavLink
                    exact={true}
                    to='/kategorije-stranice'
                    className='p-r'
                    onClick={(e) => e.stopPropagation()}>
                    <span className='pl-15 title d-inline-block'>Kategorije stranica</span>
                  </NavLink>
                </div>
              </li>
              <li>
                <NavLink exact={true} to='/tabele' className='p-r'>
                  <span className='icon d-inline-block mr-10'>
                    <TablesIcon className='fill' />
                  </span>
                  <span className='title d-inline-block'>Tabele</span>
                </NavLink>
              </li>

              <li>
                <NavLink exact={true} to='/utakmice' className='p-r'>
                  <span className='icon d-inline-block mr-10'>
                    <FootbalMatchIcon className='fill' />
                  </span>
                  <span className='title d-inline-block'>Utakmice</span>
                </NavLink>
              </li>
              <li>
                <NavLink exact={true} to='/takmicenja' className=' p-r'>
                  <span className='icon d-inline-block mr-10'>
                    <TrophyIcon className='fill' />
                  </span>
                  <span className='title d-inline-block'>Takmičenja</span>
                </NavLink>
              </li>
              <li>
                <NavLink exact={true} to='/klubovi' className=' p-r'>
                  <span className='icon d-inline-block mr-10'>
                    <TeamsIcon className='fill' />
                  </span>
                  <span className='title d-inline-block'>Klubovi</span>
                </NavLink>
              </li>
              <li className='subMenu'>
                <a
                  className='subMenuToggle'
                  onClick={(e) => this.toggleDropdown(e, "playersDropdown")}>
                  <span className='icon d-inline-block mr-10'>
                    <PlayerIcon className='fill' />
                  </span>
                  <span className='title d-inline-block'>Igrači</span>
                </a>
                <div
                  style={{ height: this.state.playersDropdown ? "100px" : 0 }}
                  className='subMenuDropdown'
                  id='playersDropdown'>
                  <NavLink
                    exact={true}
                    to='/igraci'
                    className='p-r'
                    onClick={(e) => e.stopPropagation()}>
                    <span className='pl-15 title d-inline-block'>Liste igrača</span>
                  </NavLink>
                  <NavLink
                    exact={true}
                    to='/kategorije-igraci'
                    className='p-r'
                    onClick={(e) => e.stopPropagation()}>
                    <span className='pl-15 title d-inline-block'>Kategorije igrača</span>
                  </NavLink>
                </div>
              </li>
              <li>
                <NavLink exact={true} to='/strucni-stab' className=' p-r'>
                  <span className='icon d-inline-block mr-10'>
                    <StaffIcon className='fill' />
                  </span>
                  <span className='title d-inline-block'>Stručni štab</span>
                </NavLink>
              </li>
              <li>
                <NavLink exact={true} to='/video' className=' p-r'>
                  <span className='icon d-inline-block mr-10'>
                    <VideosIcon className='fill' />
                  </span>
                  <span className='title d-inline-block'>Video</span>
                </NavLink>
              </li>
              <li>
                <NavLink exact={true} to='/partneri' className='p-r'>
                  <span className='icon d-inline-block mr-10'>
                    <SponsorsIcon className='fill' />
                  </span>
                  <span className='title d-inline-block'>Partneri</span>
                </NavLink>
              </li>
              <li>
                <NavLink exact={true} to='/organizacije' className='p-r'>
                  <span className='icon d-inline-block mr-10'>
                    <OrganisationsIcon className='fill' />
                  </span>
                  <span className='title d-inline-block'>Organizacije</span>
                </NavLink>
              </li>
              <li className='subMenu'>
                <a
                  className='subMenuToggle'
                  onClick={(e) => this.toggleDropdown(e, "productsDropdown")}>
                  <span className='icon d-inline-block mr-10'>
                    <ProductIcon className='fill' />
                  </span>
                  <span className='title d-inline-block'>Proizvodi</span>
                </a>
                <div
                  style={{ height: this.state.productsDropdown ? "150px" : 0 }}
                  className='subMenuDropdown'
                  id='productsDropdown'>
                  <NavLink
                    exact={true}
                    to='/proizvodi/1'
                    className='p-r'
                    onClick={(e) => e.stopPropagation()}>
                    <span className='pl-15 title d-inline-block'>Proizvodi</span>
                  </NavLink>
                  <NavLink
                    exact={true}
                    to='/kategorije-proizvodi'
                    className='p-r'
                    onClick={(e) => e.stopPropagation()}>
                    <span className='pl-15 title d-inline-block'>Kategorije</span>
                  </NavLink>
                  <NavLink
                    exact={true}
                    to='/popusti'
                    className='p-r'
                    onClick={(e) => e.stopPropagation()}>
                    <span className='pl-15 title d-inline-block'>Popusti</span>
                  </NavLink>
                </div>
              </li>
              <li>
                <NavLink exact={true} to='/porudzbine' className='p-r'>
                  <span className='icon d-inline-block mr-10'>
                    <OrdersIcon className='fill' />
                  </span>
                  <span className='title d-inline-block'>Porudžbine</span>
                </NavLink>
              </li>
              <li>
                {/*<NavLink exact={true} to="/podesavanja" className=" p-r">*/}
                {/*  <span className="icon d-inline-block mr-10">*/}
                {/*    <SettingsIcon className="fill" />*/}
                {/*  </span>*/}
                {/*  <span className="title d-inline-block">Podešavanja</span>*/}
                {/*</NavLink>*/}
              </li>
            </ul>
          </div>
        </nav>
      </>
    );
  }
}

export default Navbar;
