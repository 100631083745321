import React from "react";
import Select from "react-select";

const SearchableDropdown = ({
  data,
  placeholder,
  name,
  handleChange,
  value,
  index
}) => {
  return !value ? (
    <Select
      className="basic-single"
      classNamePrefix="select"
      isSearchable={true}
      name="color"
      options={data}
      noDataRenderer={"Nema rezultata"}
      onChange={e => handleChange(name, e.value, index)}
      placeholder={placeholder}
      menuPlacement="auto"
      // menuIsOpen
    />
  ) : (
    <Select
      className="basic-single"
      classNamePrefix="select"
      isSearchable={true}
      name="color"
      options={data}
      noDataRenderer={"Nema rezultata"}
      onChange={e => handleChange(name, e, true)}
      placeholder={placeholder}
      value={value}
      menuPlacement="auto"
      // menuIsOpen
    />
  );
};

export default SearchableDropdown;
