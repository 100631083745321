import React, { Component } from "react";
import {
  deleteOrder,
  deleteProduct,
  getAllOrders,
  getAllProducts,
} from "../../functions/postFunctions";
import { LinkButton, CustomButton } from "../../components/Buttons/Button";
import Table from "../../components/Table/Table";
import { dateLocalisation } from "../../functions/functions";

class OrdersPage extends Component {
  state = {
    theads: ["Detalji naručioca", "Adresa", "Vrsta plaćanja", "Poručeno", "Total", "Akcije"],
    orders: [],
    removeLoadMore: true,
    page: 1,
    categoryList: [],
    currentCategory: null,
  };

  componentDidMount() {
    this.props.setBreadcrumbs("Porudžbine");

    getAllOrders().then((res) => {
      console.log(res);
      if (res.success) {
        this.setState({ orders: res.orders });
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.currentCategory !== this.state.currentCategory && this.state.currentCategory) {
      this.loadNews();
    }
  }

  handleDeletePage = (id) => {
    const { orders } = this.state;
    const newOrders = orders.filter((e) => e.id !== id);
    deleteOrder(id, this.props.token).then((res) => {
      if (res.success) {
        this.setState({ orders: newOrders });
      }
    });
  };

  loadNews = () => {
    const { currentCategory } = this.state;
    getAllProducts(20, 0, currentCategory.value).then((res) => {
      if (res.success) {
        this.setState({
          products: res.products,
          page: 1,
          removeLoadMore: res.products.length < 20,
        });
      }
    });
  };

  loadMoreNews = () => {
    const { page, currentCategory } = this.state;
    getAllProducts(20, page * 20, currentCategory.value).then((res) => {
      if (res.success) {
        this.setState(({ products, page }) => ({
          products: [...products, ...res.products],
          page: page + 1,
          removeLoadMore: res.products.length < 20,
        }));
      }
    });
  };

  handleInput = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  render() {
    const { theads, orders, categoryList, currentCategory } = this.state;
    return (
      <div>
        {/*<div className="pageTop mb-40">*/}
        {/*  /!*<div className="row">*!/*/}
        {/*  /!*  <div className="col-md-3">*!/*/}
        {/*  /!*    <SearchableDropdown*!/*/}
        {/*  /!*      data={categoryList}*!/*/}
        {/*  /!*      placeholder="Kategorija"*!/*/}
        {/*  /!*      name="currentCategory"*!/*/}
        {/*  /!*      handleChange={this.handleInput}*!/*/}
        {/*  /!*      value={currentCategory}*!/*/}
        {/*  /!*    />*!/*/}
        {/*  /!*  </div>*!/*/}
        {/*  /!*  <div className="col-md-9">*!/*/}
        {/*  /!*    <LinkButton href="dodaj-proizvod">Dodaj proizvod</LinkButton>*!/*/}
        {/*  /!*  </div>*!/*/}
        {/*  /!*</div>*!/*/}
        {/*</div>*/}
        <Table theads={theads}>
          {orders.map((e, index) => {
            return (
              <tr key={index}>
                <td className='td px-10'>
                  <div className='wrapper d-flex flex-column'>
                    <span className='f-s-16'>{e.name}</span>
                    <span className='f-s-16'>{e.email}</span>
                    <span className='f-s-16'>{e.phone}</span>
                  </div>
                </td>
                <td className='td px-10'>
                  <div className='wrapper d-flex flex-column'>
                    <span className='f-s-16'>{e.address}</span>
                    <span className='f-s-16'>{e.city}</span>
                  </div>
                </td>
                <td className='td px-10'>
                  <span className='f-s-16'>{e.payment_type}</span>
                </td>
                <td className='td px-10'>
                  <span className='f-s-16'>{dateLocalisation(e.created_at)}</span>
                </td>
                <td className='td px-10'>
                  <span className='f-s-16'>{e.order_total} &euro;</span>
                </td>
                <td className='td px-10' style={{ minWidth: "220px" }}>
                  <LinkButton href={`porudzbine/${e.id}`} className='mr-20'>
                    Detalji
                  </LinkButton>
                  <CustomButton onClick={() => this.handleDeletePage(e.id)} className='red'>
                    Izbriši
                  </CustomButton>
                </td>
              </tr>
            );
          })}
        </Table>
        {!this.state.removeLoadMore ? (
          <div className='wrapper mt-40 d-flex justify-content-center'>
            <CustomButton onClick={this.loadMoreNews}>Učitaj još</CustomButton>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default OrdersPage;
