import React, { Component } from "react";
import { SortableContainer, SortableElement, arrayMove } from "react-sortable-hoc";
import { deleteProductImage, sortProductImages } from "../../functions/postFunctions";
import { DeleteIcon } from "../icons/icons";
import { CustomButton } from "../Buttons/Button";

const server = "https://admin.fk-buducnost.me";
class SortableGrid extends Component {
  state = {
    items: [],
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState(
      {
        items: arrayMove(this.state.items, oldIndex, newIndex),
      },
      () => {
        if (this.props.edit) {
          sortProductImages(this.state.items, this.props.token).then((res) => {});
        }
      }
    );
  };

  componentDidMount() {
    this.setState({ items: this.props.images });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.images !== this.props.images) {
      this.setState({ items: this.props.images });
    }
  }

  deleteImage = (e, image, id) => {
    e.preventDefault();
    e.stopPropagation();
    deleteProductImage(image, id, this.props.token).then((res) => {
      if (res.success) {
        this.props.removeImage(id);
      }
    });
  };

  render() {
    const SortableItem = SortableElement(({ item }) => (
      <div className='item'>
        <div className='inner-item p-r'>
          <div className='delete p-a right-0' style={{ zIndex: 99999999 }}>
            <CustomButton className='red' onClick={(e) => this.deleteImage(e, item.image, item.id)}>
              <DeleteIcon />
            </CustomButton>
          </div>
          <img src={`${server}/${item.image}`} style={{ width: "100%" }} alt={item.image} />
        </div>
      </div>
    ));

    const SortableList = SortableContainer(({ items }) => (
      <div className=''>
        <div className='row'>
          {items.map((item, index) => (
            <div className='col-lg-3' key={index}>
              <SortableItem index={index} item={item} />
            </div>
          ))}
        </div>
      </div>
    ));

    return (
      <SortableList
        items={this.state.items}
        onSortEnd={this.onSortEnd}
        axis='xy'
        helperClass='SortableHelper'
      />
    );
  }
}

export default SortableGrid;
