import React, { Component } from "react";

import { CustomButton } from "../Buttons/Button";
import SearchableDropdown from "../Form/Dropdown/SearchableDropdown";
import SearchableDropdownMultiple from "../Form/Dropdown/SearchableDropdownMultiple";
import SortableListSimple from "../SortableGrid/SortableListSimple";
import { getAllProductsForSorting } from "../../functions/postFunctions";

class SortProducts extends Component {
  state = {
    products: [],
    category: 0,
  };

  handleInput = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.category !== this.state.category) {
      getAllProductsForSorting(this.state.category).then((res) => {
        if (res.success) {
          this.setState({ products: res.products });
        }
      });
    }
  }

  render() {
    const { visible, hideModal, sortableCategories } = this.props;
    const { products } = this.state;
    return (
      <div
        className={`addTeamModal sortItems d-flex justify-content-center align-items-center ${
          visible ? "visible" : ""
        }`}>
        <div className='row'>
          <div className='sortItemsContent'>
            <div className='category pt-20 d-flex justify-content-center'>
              <div className='col-md-5'>
                <SearchableDropdownMultiple
                  data={sortableCategories}
                  placeholder='Kategorija'
                  name='category'
                  handleChange={this.handleInput}
                />
              </div>
            </div>
            <div className='sorting'>
              {products.length > 0 ? (
                <SortableListSimple items={products} token={this.props.token} />
              ) : (
                <div className='mt-40'>
                  <h3 className='text-center'>Nema Rezultata</h3>
                </div>
              )}
            </div>
            <div className='row action'>
              <div className='col-lg-12 d-flex justify-content-center align-items-center'>
                <CustomButton className='mr-30' onClick={hideModal}>
                  Završi
                </CustomButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SortProducts;
