import React, { Component } from "react";
import {
  deleteCompetition,
  deletePartner,
  getAllCompetitions,
  getAllPartners
} from "../../functions/postFunctions";
import { CustomButton } from "../../components/Buttons/Button";
import Table from "../../components/Table/Table";
import AddCompetition from "../../components/AddCompetition/AddCompetition";
import EditCompetition from "../../components/EditCompetition/EditCompetition";
import AddPartner from "../../components/AddPartner/AddPartner";
import EditPartner from "../../components/EditPartner/EditPartner";
import SortableListPartners from "../../components/SortableGrid/SortableListPartners";
import AddOrganisation from "../../components/AddOrganisation/AddPartner";
import EditOrganisation from "../../components/EditOrganisation/EditPartner";

class OrganizacijePage extends Component {
  state = {
    theads: ["Logo", "Link", "Akcije"],
    partners: [],
    showAddCompetition: false,
    showEditCompetition: false,
    competitionForEdit: null
  };

  componentDidMount() {
    this.props.setBreadcrumbs("Organizacije");
    getAllPartners(1).then(res => this.setState({ partners: res.partners }));
  }

  handleDeletePage = id => {
    deletePartner(id, this.props.token).then(res => {
      if (res.success) {
        this.setState({ partners: res.partners });
      }
    });
  };

  showModal = (name, id) => {
    let partner;
    if (id) {
      const { partners } = this.state;
      partner = partners.find(e => e.id === id);
    }
    this.setState({ [name]: true, competitionForEdit: partner });
  };

  hideModal = () => {
    this.setState({
      showAddCompetition: false,
      showEditCompetition: false,
      competitionForEdit: null
    });
  };

  addCompetitionToList = partner => {
    this.setState(prevState => ({
      partners: [...prevState.partners, partner],
      showAddCompetition: false
    }));
  };

  refreshList = () => {
    getAllPartners(1).then(res =>
      this.setState({
        partners: res.partners,
        showEditCompetition: false,
        competitionsForEdit: null
      })
    );
  };

  render() {
    const { theads, partners, competitionForEdit } = this.state;
    let server;
    if (process.env.NODE_ENV === "development") {
      server = "http://127.0.0.8/";
    } else {
      server = "https://admin.fk-buducnost.me/";
    }
    return (
      <div>
        <div className="pageTop mb-40">
          <CustomButton onClick={() => this.showModal("showAddCompetition")}>
            Dodaj organizaciju
          </CustomButton>
          <AddOrganisation
            visible={this.state.showAddCompetition}
            hideModal={this.hideModal}
            token={this.props.token}
            addCompetitionToList={this.addCompetitionToList}
          />
          <EditOrganisation
            visible={this.state.showEditCompetition}
            hideModal={this.hideModal}
            token={this.props.token}
            refreshList={this.refreshList}
            competition={competitionForEdit}
          />
        </div>
        <SortableListPartners
          items={partners}
          showModal={this.showModal}
          deleteMenuItem={this.handleDeletePage}
          token={this.props.token}
          type={1}
        />
      </div>
    );
  }
}

export default OrganizacijePage;
