import { CustomInput, CustomTextArea } from "../Form/Inputs/Inputs";
import React, { Component } from "react";
import { editProductCategory } from "../../functions/postFunctions";

import { CustomButton } from "../Buttons/Button";
import SearchableDropdown from "../Form/Dropdown/SearchableDropdown";
import { addMenuItemValidiation } from "../../functions/validateFunctions";
import { createSlug } from "../../functions/functions";
import axios from "axios";

let server;
if (process.env.NODE_ENV === "development") {
  server = "http://127.0.0.1:8000/api";
} else {
}
server = "https://admin.benettonshop.me/api";

class EditProductCategory extends Component {
  state = {
    id: 0,
    name: "",
    logo: null,
    showErrorPopup: false,
    errors: [],
    responseErrors: [],
    menus: [],
    linkTypes: [
      { value: 0, label: "Interni link" },
      { value: 1, label: "Eksterni link" },
      // { value: 2, label: "Fajl" },
      { value: 3, label: "Placeholder" },
    ],
    linkType: null,
    link: "",
    external: false,
    pages: [],
    parent_id: null,
    language_id: 1,
    order: 0,
    currentParentMenu: {},
    currentParentID: { value: 0, label: "" },
    currentLinkType: { value: 0, label: "" },
    currentPageSelected: { value: 0, label: "" },
    description: "",
    discounts: [],
    discount: { value: 0, label: "Bez popusta" },
  };

  defaultState = {
    name: "",
    logo: null,
    showErrorPopup: false,
    errors: [],
    responseErrors: [],
    menus: [],
    linkTypes: [
      { value: 0, label: "Interni link" },
      { value: 1, label: "Eksterni link" },
      // { value: 2, label: "Fajl" },
      { value: 3, label: "Placeholder" },
    ],
    linkType: null,
    link: "",
    external: false,
    pages: [],
    parent_id: null,
    language_id: 1,
    order: 0,
  };

  componentDidMount() {}

  handleInput = (name, value) => {
    this.setState({ [name]: value });
  };

  handleFileInput = (event) => {
    this.setState({ logo: [...event] });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const { currentParentID, name, id, description, discount } = this.state;
    let orderList = [];
    let parentLevel;
    if (currentParentID) {
      orderList = this.state.menus.filter((e) => e.parent_id === currentParentID.value);
      parentLevel = this.state.menus.find((e) => e.id === currentParentID.value);
    }

    let order = 0;
    let level = 0;
    if (parentLevel) {
      level = parentLevel.level + 1;
    }
    if (orderList.length > 0) {
      order =
        Math.max.apply(
          Math,
          orderList.map((e) => e.order)
        ) + 1;
    }
    const data = {
      id,
      name,
      order,
      level,
      description,
      slug: createSlug(name),
      discount_id: discount ? discount.value : 0,
    };
    const errors = addMenuItemValidiation(this.state, true);
    if (errors.length === 0) {
      this.props.addToList([]);
      editProductCategory(data, this.props.token)
        .then((res) => {
          if (res.data) {
            this.setState({
              name: "",
              // id: 0,
            });
            this.props.addToList(res.data.categories);
          } else {
            let responseErrors = [];
            Object.keys(res.errors).forEach((key, index) => {
              responseErrors.push(res.errors[key]);
            });
            this.props.addToList(this.props.menus);
            this.setState({ responseErrors });
          }
        })
        .catch((err) => {});
    } else {
      this.setState({ errors });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.errors !== this.state.errors && this.state.errors.length > 0) {
      this.setState({ showErrorPopup: true });
    }
    if (
      prevState.responseErrors !== this.state.responseErrors &&
      this.state.responseErrors.length > 0
    ) {
      this.setState({ showErrorPopup: true });
    }
    if (prevProps.menus !== this.props.menus) {
      this.setState({ menus: this.findAllMenus(this.props.menus) });
    }

    if (prevProps.itemForEdit !== this.props.itemForEdit && this.props.itemForEdit) {
      const menuList = [
        { value: 0, label: "Nekategorisano" },
        ...this.state.menus.map((e) => ({ value: e.id, label: e.name })),
      ];
      const currentParentID = menuList.find((e) => e.value === this.props.itemForEdit.parent_id);
      const currentLinkType = this.state.linkTypes.find(
        (e) => e.value === this.getLinkType(this.props.itemForEdit)
      );
      const name = this.props.itemForEdit.name;
      const id = this.props.itemForEdit.id;
      const description = this.props.itemForEdit.description;
      const discount = this.state.discounts.find(
        (e) => e.value === this.props.itemForEdit.discount_id
      );
      this.setState({
        currentParentID,
        currentLinkType,
        name,
        id,
        description,
        discount,
      });
    }
    if (prevProps.itemForEdit !== this.props.itemForEdit && !this.props.itemForEdit) {
      this.setState({ name: "" });
    }
  }

  getLinkType = (el) => {
    if (el.link && el.external === 0) {
      return 0;
    }
    if (el.link && el.external === 1) {
      return 1;
    }
    if (el.placeholder === 1) {
      return 3;
    }
  };

  removePopup = () => {
    this.setState({ showErrorPopup: false, errors: [], responseErrors: [] });
  };

  findAllMenus = (array) => {
    let menus = [];
    const handleRecursion = (arr) => {
      arr.forEach((e) => {
        menus.push(e);
        if (e.children.length > 0) {
          handleRecursion(e.children);
        }
      });
    };
    handleRecursion(array);
    return menus;
  };

  renderLink = (linkType) => {
    let item;
    switch (linkType) {
      case 0:
        item = (
          <>
            <label
              htmlFor='linkType'
              className='f-s-14 text-color-font-l'
              style={{
                color: "#7ea5cb",
                marginBottom: "5px",
                display: "block",
              }}>
              Izaberite stranicu
            </label>
            <SearchableDropdown
              data={this.state.pages}
              placeholder='Izaberite stranicu'
              name='currentPageSelected'
              handleChange={this.handleInput}
              value={this.state.currentPageSelected}
            />
          </>
        );
        break;
      case 1:
        item = (
          <>
            <label
              htmlFor='linkType'
              className='f-s-14 text-color-font-l'
              style={{
                color: "#7ea5cb",
                marginBottom: "5px",
                display: "block",
              }}>
              Link
            </label>
            <CustomInput
              label='Link'
              value={this.state.link}
              handleChange={this.handleInput}
              name='link'
              index={0}
              counter={false}
            />
          </>
        );
        break;
      case 2:
        item = 2;
        break;
      case 3:
        item = "";
        break;
      default:
        item = "";
        break;
    }
    return item;
  };

  render() {
    const { name, menus, description, discounts } = this.state;
    const { visible, hideModal } = this.props;
    const menuList = [
      { value: null, label: "Nekategorisana" },
      ...menus.filter((e) => e.name !== name).map((e) => ({ value: e.id, label: e.name })),
    ];
    if (this.props.itemForEdit) {
      return (
        <div
          className={`addTeamModal d-flex justify-content-center align-items-center ${
            visible ? "visible" : ""
          }`}>
          <div className={` errorPopup ${this.state.showErrorPopup ? "popupShown" : ""}`}>
            <div className='content py-20 px-10 d-flex justify-content-between flex-column'>
              <h3 className='f-s-18 f-w-4 uppercase text-center'>
                Problemi prilikom izmjene stavke
              </h3>
              {this.state.errors.length > 0 ? (
                <ul className='mt-30'>
                  {this.state.errors.map((e, index) => {
                    return (
                      <li key={index} className='f-s-16 text-center'>
                        {e}
                      </li>
                    );
                  })}
                </ul>
              ) : (
                <ul className='mt-30'>
                  {this.state.responseErrors.map((e) => {
                    return e.map((el, index) => (
                      <li key={index} className='f-s-16 text-center'>
                        {e}
                      </li>
                    ));
                  })}
                </ul>
              )}
              <div className='button text-center mt-30'>
                <CustomButton onClick={this.removePopup}>Zatvori</CustomButton>
              </div>
            </div>
          </div>
          <div className='addTeamContent py-30 px-30'>
            <h4 className='text-color-primary f-s-20 lh text-center mb-20'>
              Izmjeni stavku menija
            </h4>
            <div className='row mt-30'>
              <div className='col-md-12'>
                <label
                  htmlFor='linkType'
                  className='f-s-14 text-color-font-l'
                  style={{
                    color: "#7ea5cb",
                    marginBottom: "5px",
                    display: "block",
                  }}>
                  Naziv
                </label>
                <CustomInput
                  // label="Naziv"
                  value={name}
                  handleChange={this.handleInput}
                  name='name'
                  index={0}
                  counter={false}
                />
              </div>
            </div>
            {/* <div className='row mt-30'>
              <div className='col-12'>
                <SearchableDropdown
                  data={discounts}
                  placeholder='Popust'
                  name='discount'
                  handleChange={this.handleInput}
                  value={this.state.discount}
                />
              </div>
            </div> */}
            <div className='row mt-30'>
              <div className='col-12'>
                <label
                  htmlFor='parentMenu'
                  className='f-s-14 text-color-font-l'
                  style={{
                    color: "#7ea5cb",
                    marginBottom: "5px",
                    display: "block",
                  }}>
                  Opis kategorije
                </label>
                <CustomTextArea
                  // label="Naziv"
                  value={description}
                  handleChange={this.handleInput}
                  name='description'
                  index={0}
                  counter={false}
                />
              </div>
            </div>
            <div className='row mt-40'>
              <div className='col-lg-12 d-flex justify-content-center'>
                <CustomButton className='mr-30' onClick={(e) => this.handleSubmit(e)}>
                  Izmjeni stavku
                </CustomButton>
                <CustomButton className='red' onClick={hideModal}>
                  Odustani
                </CustomButton>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return "";
    }
  }
}

export default EditProductCategory;
