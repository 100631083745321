import React, { Component } from 'react';
import {
  deletePlayer,
  getAllCategories,
  getAllPlayers,
} from '../../functions/postFunctions';
import { CustomButton, LinkButton } from '../../components/Buttons/Button';
import Table from '../../components/Table/Table';
import {
  dateLocalisation,
  formatDateForSafari,
} from '../../functions/functions';
import SearchableDropdown from '../../components/Form/Dropdown/SearchableDropdown';
import { PlayerAvatarIcon } from '../../components/icons/icons';

class PlayersPage extends Component {
  state = {
    theads: [
      'Fotografija',
      'Ime i prezime',
      'Datum Rođenja',
      'Broj',
      'Pozicija',
      'Akcije',
    ],
    players: [],
    categories: [],
    category: '',
    currentCategory: { value: '', label: '' },
  };

  componentDidMount() {
    this.props.setBreadcrumbs('Igrači');

    getAllCategories().then((res) => {
      if (res.success) {
        let categories = res.categories.map((e) => ({
          value: e.id,
          label: e.name,
        }));
        const currentCategory = categories.find((e) => e.label === 'Prvi tim');
        categories = categories.filter((e) => e.label !== 'Ženski tim');
        this.setState({
          categories,
          currentCategory,
          category: currentCategory.value,
        });
      }
    });
  }

  handleDeletePlayer = (id) => {
    deletePlayer(id, this.props.token).then((res) => {
      if (res.success) {
        this.setState({ players: res.players });
      }
    });
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevState.currentCategory !== this.state.currentCategory &&
      this.state.currentCategory
    ) {
      const { currentCategory } = this.state;
      this.handleGetAllPlayers(currentCategory.value);
    }
  }

  handleGetAllPlayers = (category) => {
    getAllPlayers(category).then((res) => {
      if (res.success) {
        this.setState({ players: res.players });
      }
    });
  };

  handleInput = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  render() {
    const { theads, players, categories, category, currentCategory } =
      this.state;
    let server;
    if (process.env.NODE_ENV === 'development') {
      server = 'http://127.0.0.8/';
    } else {
      server = 'https://admin.fk-buducnost.me/';
    }
    return (
      <div>
        <div className="pageTop mb-40">
          <div className="row">
            <div className="col-md-4">
              <SearchableDropdown
                data={categories}
                placeholder="Izaberi Kategoriju"
                name="currentCategory"
                handleChange={this.handleInput}
                value={currentCategory}
              />
            </div>
            <div className="col-md-4">
              {category !== '' ? (
                <LinkButton href={`dodaj-igraca/${category}`}>
                  Dodaj igrača
                </LinkButton>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
        <Table theads={theads}>
          {players.map((e, index) => {
            return (
              <tr key={index}>
                <td className="td px-10">
                  {e.image ? (
                    <img
                      src={server + e.image}
                      alt={e.slug}
                      style={{ maxHeight: '100px' }}
                    />
                  ) : (
                    <PlayerAvatarIcon className="avatar" />
                  )}
                </td>
                <td className="td px-10">
                  <div className="wrapper">
                    <span className="f-s-16">{e.name}</span>
                  </div>
                </td>
                <td className="td px-10">
                  <div className="wrapper d-flex align-items-center justify-content-start">
                    <span className="f-s-16 mr-10">
                      {
                        dateLocalisation(
                          formatDateForSafari(e.birth_date)
                        ).split(' ')[0]
                      }
                    </span>
                  </div>
                </td>
                <td className="td px-10">
                  <span className="f-s-16">{e.number}</span>
                </td>
                <td className="td px-10">
                  <span className="f-s-16 capitalize">{e.position}</span>
                </td>
                <td className="td px-10" style={{ minWidth: '220px' }}>
                  <LinkButton
                    href={`izmjeni-igraca/${category}/${e.id}`}
                    className="mr-20"
                  >
                    Izmjeni
                  </LinkButton>
                  <CustomButton
                    onClick={() => this.handleDeletePlayer(e.id)}
                    className="red"
                  >
                    Izbriši
                  </CustomButton>
                </td>
              </tr>
            );
          })}
        </Table>
      </div>
    );
  }
}

export default PlayersPage;
