import React, { Component } from "react";
import { SortableContainer, SortableElement, arrayMove } from "react-sortable-hoc";

import { ListIcon } from "../icons/icons";
import { sortProducts } from "../../functions/postFunctions";

class SortableListSimple extends Component {
  state = {
    items: [],
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState(
      {
        items: arrayMove(this.state.items, oldIndex, newIndex),
      },
      () => this.handleSortMenus()
    );
  };

  componentDidMount() {
    this.setState({ items: this.props.items });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.items !== this.props.items) {
      this.setState({ items: this.props.items });
    }
  }

  handleSortMenus = () => {
    sortProducts(this.state.items, this.props.token).then(() => null);
  };

  render() {
    const SortableItem = SortableElement(({ item, index }) => (
      <li
        className={`level-1`}
        style={{ paddingLeft: `${item.level * 20}px`, listStyle: "none" }}
        key={index}>
        <span
          className='f-s-16 d-flex align-items-center justify-content-between pl-40'
          style={{ height: "50px" }}>
          <span className='left d-flex align-items-center'>
            <ListIcon className='fill mr-10' />
            {item.image ? (
              <img
                src={`/${item.image}`}
                alt={item.name}
                style={{ maxHeight: "50px" }}
                className='mr-10'
              />
            ) : (
              ""
            )}
            {item.name}
          </span>
        </span>
      </li>
    ));

    const SortableList = SortableContainer(({ items }) => {
      return (
        <ul>
          {items.map((item, index) => (
            <React.Fragment key={index}>
              <SortableItem index={index} item={item} />
            </React.Fragment>
          ))}
        </ul>
      );
    });

    return (
      <SortableList
        items={this.state.items}
        onSortEnd={this.onSortEnd}
        axis='y'
        helperClass='SortableHelper'
        key={this.props.key}
      />
    );
  }
}

export default SortableListSimple;
